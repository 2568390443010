import { getFile } from 'services/http/medical_case';

const extensions = {
  fusdata: 'nii',
  filelist: 'csv',
  segdata: 'tar.gz',
  csv: 'csv',
  dicom: 'tar.gz',
  data: 'tar.gz',
  pdf: 'pdf'
};

const suffix = {
  fusdata: 'mc_combined',
  filelist: 'filelist',
  segdata: 'segdata',
  csv: 'inference_roi_volumes',
  dicom: 'dicom',
  data: 'generated_data',
  pdf: 'PDF_results'
};

export const downloadFile = (medicalCaseId, type) =>
  getFile(medicalCaseId, type).then((res) => {
    console.log(res);
    const fileData = res.data;
    const downloadUrl = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute(
      'download',
      `${medicalCaseId}_${suffix[type]}.${extensions[type]}`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
