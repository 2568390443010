import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import MedicalCaseList from 'components/medical_case/MedicalCaseList';
import { Box, Container, Alert, AlertTitle, Button } from '@material-ui/core';
import MedicalCaseListToolbar from 'components/medical_case/MedicalCaseListToolbar';

const MedicalCase = () => {
  const [seenMessage, setSeenMessage] = useState(false);

  useEffect(() => {
    const seen = localStorage.getItem('seen_message');
    setSeenMessage(seen === 'true');
  }, []);
  return (
    <>
      <Helmet>
        <title>Medical Cases</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          {/* <div style={{ paddingBottom: '2%' }}>
            <Alert
              severity="warning"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    localStorage.setItem('seen_message', 'true');
                    setSeenMessage((prev) => {
                      localStorage.setItem(
                        'seen_message',
                        !prev ? 'true' : false
                      );
                      return !prev;
                    });
                  }}
                >
                  {seenMessage ? 'HIDE' : 'SHOW'}
                </Button>
              }
            >
              <AlertTitle>Info</AlertTitle>
              {seenMessage && (
                <div>
                  <strong>MRI ACQUISITION INFORMATION:</strong>
                  <p>
                    In order to be processed, MRI data must be acquired using
                    DESS imaging protocol with a sagittal resolution of 384x384
                    pixels with 160 layers along the orthogonal direction.
                  </p>
                  <p>
                    The automated segmentation model was trained using the
                    following DESS image acquisition specifications. Complying
                    with these specifications will secure high precision
                    results. Sagittal 3D DESS WE, TR/TE, 16.3/4.7 ms; flip
                    angle, 25 degrees; slice thickness/gap, 0.7/0 mm; excitation
                    number, 1; matrix size, 384 × 307; field of view, 140 mm;
                    resolution, 0.365/0.456 mm; receiver bandwidth, 248
                    Hz/pixel; phase direction, S/I.
                  </p>
                </div>
              )}
            </Alert>
          </div>  */}

          <MedicalCaseListToolbar />
          <MedicalCaseList />
        </Container>
      </Box>
    </>
  );
};

export default MedicalCase;
