import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  Grid,
  OutlinedInput
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import {
  setFilterPatientName,
  setFilterUsername,
  setFilterDateInterval
} from 'redux/medical_cases/medical_cases.actions';
import CenteredModal from 'components/ui/CenteredModal';
import UploadCaseForm from 'components/medical_case/UploadCaseForm';
import { isValidDateInterval } from 'utils/date';
import { UserRoles } from 'constants/userRoles';
import { useSnackbar } from 'notistack';

const MedicalCaseListToolbar = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isUploadingMriToServer, setIsUploadingMriToServer] = useState(false);
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { role } = useSelector((state) => state.userInfo.user);
  const { isLoading, casesList } = useSelector((state) => state.medicalCases);

  const [dateFilter, setDateFilter] = useState({
    initialDate: new Date().toJSON().slice(0, 10),
    finalDate: new Date().toJSON().slice(0, 10)
  });

  const handleChange = (e) => {
    setDateFilter({ ...dateFilter, [e.target.name]: e.target.value });
  };

  const handleChangeDateFilter = () => {
    dispatch(setFilterDateInterval(dateFilter));
  };

  const handleUploadCase = () => {
    setModalOpen(true);
    if (role === UserRoles.FREE && !isLoading && casesList.length >= 3) {
      enqueueSnackbar(
        'You can only see the last 3 cases. The least recent case will be hidden.',
        {
          variant: 'warning'
        }
      );
    }
  };

  return (
    <>
      <Box {...props}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          {role !== UserRoles.FREE && (
            <Button
              color="primary"
              variant="contained"
              onClick={handleUploadCase}
            >
              Upload Case
            </Button>
          )}
        </Box>
        {role !== UserRoles.FREE && (
          <Box sx={{ mt: 3 }}>
            <Card>
              <CardContent>
                <Grid
                  container
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Grid item sm={4} xs={12} flexDirection="column">
                    <Grid container>
                      {role === UserRoles.ADMIN && (
                        <Grid container>
                          <Typography variant="body2" color="GrayText">
                            Username
                          </Typography>
                          <TextField
                            size="small"
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SvgIcon fontSize="small" color="action">
                                    <SearchIcon />
                                  </SvgIcon>
                                </InputAdornment>
                              )
                            }}
                            placeholder="John Doe"
                            variant="outlined"
                            onChange={(e) =>
                              dispatch(
                                setFilterUsername(e.target.value?.trim())
                              )
                            }
                          />
                        </Grid>
                      )}
                      <Typography
                        variant="body2"
                        color="GrayText"
                        textAlign="left"
                      >
                        Patient name
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SvgIcon fontSize="small" color="action">
                                <SearchIcon />
                              </SvgIcon>
                            </InputAdornment>
                          )
                        }}
                        placeholder="John Doe"
                        variant="outlined"
                        onChange={(e) =>
                          dispatch(setFilterPatientName(e.target.value?.trim()))
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item sm={5} justifySelf="flex-end">
                    <Grid container>
                      <Grid
                        item
                        sm
                        xs={12}
                        marginRight="1rem"
                        justifySelf="flex-end"
                      >
                        <Typography variant="body2" color="GrayText">
                          Initial date
                        </Typography>
                        <OutlinedInput
                          id="initialDate"
                          name="initialDate"
                          type="date"
                          size="small"
                          value={dateFilter.initialDate}
                          inputProps={{
                            max: dateFilter.finalDate
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item sm xs={12} marginRight="1rem">
                        <Typography variant="body2" color="GrayText">
                          Final date
                        </Typography>
                        <OutlinedInput
                          id="finalDate"
                          name="finalDate"
                          type="date"
                          size="small"
                          value={dateFilter.finalDate}
                          inputProps={{
                            max: new Date().toJSON().slice(0, 10)
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        sm
                        xs={12}
                        marginRight="1rem"
                        alignSelf="flex-end"
                        paddingTop="0.5rem"
                      >
                        <Button
                          onClick={handleChangeDateFilter}
                          style={{ padding: '8px 16px' }}
                          variant="contained"
                          size="medium"
                          disabled={
                            !isValidDateInterval(
                              dateFilter.initialDate,
                              dateFilter.finalDate
                            )
                          }
                        >
                          Filter
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>

      <CenteredModal
        open={modalOpen}
        onClose={() => !isUploadingMriToServer && setModalOpen(false)}
      >
        <UploadCaseForm
          setUploadingMri={setIsUploadingMriToServer}
          isUploadingMriToServer={isUploadingMriToServer}
        />
      </CenteredModal>
    </>
  );
};

export default MedicalCaseListToolbar;
