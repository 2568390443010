import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { login } from 'services/http/auth';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setUser,
  setIsLoggedIn,
  setTokenExpired
} from 'redux/user/user.actions';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoginIn, setIsLoginIn] = useState(false);

  const tokenExpired = useSelector((state) => state.userInfo.tokenExpired);

  useEffect(() => {
    if (tokenExpired) {
      enqueueSnackbar('Your session expired. Please login.', {
        variant: 'info'
      });
      dispatch(setTokenExpired(false));
    }
  }, [tokenExpired]);

  const handleLogin = async (email, password) => {
    setIsLoginIn(true);
    try {
      const user = await login(email, password);
      if (user) {
        dispatch(setUser(user));
        dispatch(setIsLoggedIn(true));
        navigate('/app/medical_cases', { replace: true });
      }
    } catch (e) {
      if (e.response?.data?.message) {
        enqueueSnackbar(e.response.data.message, {
          variant: 'error',
          autoHideDuration: 6000
        });
      } else {
        enqueueSnackbar('An error ocurred. Please try again.', {
          variant: 'error'
        });
      }
      setIsLoginIn(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={({ email, password }) => handleLogin(email, password)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the TensorCare platform
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {isLoginIn ? <CircularProgress size={24} /> : 'Sign in now'}
                  </Button>
                </Box>
                <Link
                  component={RouterLink}
                  to={`/forgot-password/${values.email}`}
                  variant="h6"
                >
                  Forgot password?
                </Link>
                <Typography color="textSecondary" variant="body1">
                  Dont have an account?
                  <Link component={RouterLink} to="/register" variant="h6">
                    {' '}
                    Sign up
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
